<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col>
        <el-breadcrumb>
          <el-breadcrumb-item class="pointer" :to="{ path: '/member' }">导航</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li class="menu-item text-cut" v-for="(item, i) in tabs" :key="item"
                :class="seeThis == i ? 'active' : ''">
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip class="item" effect="dark" content="保存基本信息和扩展属性" placement="bottom">
                          <el-button type="primary" size="small" @click="submitForm('member')">保存</el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="返回列表" placement="bottom">
                          <el-button @click="goIndex()" size="small">返回</el-button>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form :model="member" ref="member" label-width="150px" class="member" size="mini">
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="用户名" prop="memberName">
                        <el-input v-model="member.memberName" disabled></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="手机号" prop="phone">
                        <el-input v-model="member.phone" disabled></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="logo" prop="logo">
                        <el-image :src="member.logo" fit="contain" :preview-src-list="[member.logo]"
                          style="height: 70px"></el-image>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="性别" prop="sex">
                        <span>{{ mySex(member.sex) }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="签名" prop="sign">
                        <el-input v-model="member.sign"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="生日" prop="birthday">
                        <el-input v-model="member.birthday"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="星级" prop="star">
                        <el-input v-model="member.star"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="地址" prop="address">
                        <el-input v-model="member.address"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="省" prop="provinceName">
                        <el-select v-model="member.provinceCode" @change="changeProvince" placeholder="请选择省"
                          class="wp100">
                          <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id+''">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="市" prop="cityName">
                        <el-select v-model="member.cityCode" @change="changeCity" placeholder="请选择城市" class="wp100">
                          <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id+''">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="区" prop="districtName">
                        <el-select v-model="member.districtCode" @change="changeDistrict" placeholder="请选择区县"
                          class="wp100">
                          <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id+''">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="街道" prop="streetName">
                        <el-select v-model="member.streetCode" @change="changeStreet" placeholder="请选择街道" class="wp100">
                          <el-option v-for="item in streetList" :key="item.streetCode" :label="item.streetName"
                            :value="item.streetCode"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="社区" prop="communityName">
                        <el-select v-model="member.communityCode" @change="changeCommunity" placeholder="请选择社区"
                          class="wp100">
                          <el-option v-for="item in communityList" :key="item.communityCode" :label="item.communityName"
                            :value="item.communityCode"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="小区" prop="villageName">
                        <el-select v-model="member.villageCode" placeholder="请选择小区" class="wp100">
                          <el-option v-for="item in villageList" :key="item.villageCode" :label="item.villageName"
                            :value="item.villageCode"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="楼栋" prop="buildName">
                        <el-input v-model="member.buildName"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="单元" prop="unitName">
                        <el-input v-model="member.unitName"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="房间" prop="roomName">
                        <el-input v-model="member.roomName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="居住类型" prop="liveType">
                        <el-input v-model="member.liveType"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      member: {
        id: "",
        memberCode: "",
        memberName: "",
        phone: "",
        unionId: "",
        miniOpneid: "",
        gzhOpenid: "",
        logo: "",
        sex: "",
        sign: "",
        createDt: "",
        updateDt: "",
        parentCode: "",
        inviteNo: "",
        longitude: "",
        latitude: "",
        pwd: "",
        birthday: "",
        star: "",
        address: "",
        consumMoney: "",
        onoff: "",
        openid: "",
        qrCode: "",
        merQrCode: "",
        memberType: "",
        provinceCode: "",
        provinceName: "",
        cityCode: "",
        cityName: "",
        districtCode: "",
        districtName: "",
        streetCode: "",
        streetName: "",
        communityCode: "",
        communityName: "",
        villageCode: "",
        villageName: "",
        buildCode: "",
        buildName: "",
        unitCode: "",
        unitName: "",
        roomCode: "",
        roomName: "",
        zhMIniOpenid: "",
        trueName: "",
      },
      provinceList: [], //省列表
      cityList: [], //城市列表
      districtList: [], //区县列表
      streetList: [], //街道列表
      communityList: [], //社区
      villageList: [], //小区
    };
  },
  computed: {
    mySex () {
      return function (sex) {
        if (sex == "1") {
          return "男";
        } else if (sex == "2") {
          return "女";
        } else {
          return "无此性别";
        }
      };
    },
  },
  methods: {

    //获取省
    getProvinceList () {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.provinceList = response.data.data;
          }
        });
    },
    //获取市
    getCityList (id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.cityList = response.data.data;
          }
        });
    },
    //获取区
    getDistrictList (id) {
      let that = this;
      that.$http
        .post("/area/queryAreaList", { parentId: id })
        .then(function (response) {
          if (response.data.code == 200) {
            that.districtList = response.data.data;
          }
        });
    },
    //获取街道
    getStreetList (districtCode) {
      let that = this;
      that.$http
        .post("/street/queryList", { districtCode: districtCode })
        .then(function (response) {
          if (response.data.code == 200) {
            that.streetList = response.data.data;
          }
        });
    },

    //获取社区
    getCommunityList (streetCode) {
      let that = this;
      that.$http
        .post("/community/queryList", { streetCode: streetCode })
        .then(function (response) {
          if (response.data.code == 200) {
            that.communityList = response.data.data;
          }
        });
    },
    //获取小区
    getVillageList (communityCode) {
      let that = this;
      that.$http
        .post("/village/queryList", { communityCode: communityCode })
        .then(function (response) {
          if (response.data.code == 200) {
            that.villageList = response.data.data;
          }
        });
    },

    changeProvince (e) {
      this.handleClearCode(1)
      this.getCityList(e);
    },
    changeCity (e) {
      this.handleClearCode(2)
      this.getDistrictList(e);
    },
    changeDistrict (e) {
      this.handleClearCode(3)
      this.getStreetList(e);
    },

    changeStreet (e) {
      this.handleClearCode(4)
      this.getCommunityList(e);
    },

    changeCommunity (e) {
      this.handleClearCode(5)
      this.getVillageList(e);
    },


    handleClearCode (level) {
      //重新选择时，清空级联列表
      switch (level) {
        case 1:
          this.member.cityCode = ''
          this.member.districtCode = ''
          this.member.streetCode = ''
          this.member.communityCode = ''
          this.member.villageCode = ''
          break;
        case 2:
          this.member.districtCode = ''
          this.member.streetCode = ''
          this.member.communityCode = ''
          this.member.villageCode = ''
          break;
        case 3:
          this.member.streetCode = ''
          this.member.communityCode = ''
          this.member.villageCode = ''
          break;
        case 4:
          this.member.communityCode = ''
          this.member.villageCode = ''
          break;
        case 5:
          this.member.villageCode = ''
          break;
        default:
          break;
      }
    },





    goIndex () {
      this.$router.push({
        path: "/member",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm (formName) {
      var that = this;
      console.log('that.member',that.member)
      that.$http
        .post("/member/save", that.member)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$router.go(-1)
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo () {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/member/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;

              if (response.data.data.memberName) {
                response.data.data.memberName = decodeURI(
                  response.data.data.memberName
                );
              }
              if(response.data.data.liveType=='1'){
                response.data.data.liveType='自住';
              }else{
                response.data.data.liveType='租住';
              }
              that.member = response.data.data
              if (that.member.provinceCode) {
                that.getCityList(that.member.provinceCode)
              }
              if (that.member.cityCode) {
                that.getDistrictList(that.member.cityCode)
              }
              if (that.member.districtCode) {
                that.getStreetList(that.member.districtCode)
              }
              if (that.member.streetCode) {
                that.getCommunityList(that.member.streetCode)
              }
              if (that.member.communityCode) {
                that.getVillageList(that.member.communityCode)
              }

            }
          });
      }
    },
    goAnchor (index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll () {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created () {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getProvinceList();
  },
  mounted () {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
